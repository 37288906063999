import React from 'react';
import {
    getImage, 
    GatsbyImage,
} from "gatsby-plugin-image";
import {
    useStaticQuery,
    graphql,
} from "gatsby";

import DemoGraphicLayout from '../../components/layout/DemoGraphicLayout';

const IndexDemoCZ = () => {
    const demoImage = useStaticQuery(
        graphql`
            {
                file(relativePath: { eq: "PayPo_CZECHY.png" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        `
    );

    const image = getImage(demoImage.file);

    return (
        <DemoGraphicLayout>
            <GatsbyImage
                image={ image }
                alt="demo page image"
            />
        </DemoGraphicLayout>
    );
};

export default IndexDemoCZ;
