import React from 'react';

import { SDemoGraphicContainer } from './styles';

const DemoGraphicLayout = ({ children }) => (
    <SDemoGraphicContainer>
        { children }
    </SDemoGraphicContainer>
);

export default DemoGraphicLayout;
